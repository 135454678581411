import { BrowserRouter, Route, Routes } from "react-router-dom";
import HomePage from "pages/home";
import AboutPage from "pages/about";
import ProjectsPage from "pages/projects";
import ContactPage from "pages/contact";
import QuotePage from "pages/quote";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/about" element={<AboutPage />} />
        <Route path="/projects" element={<ProjectsPage />} />
        <Route path="/contact" element={<ContactPage />} />
        <Route path="/quote" element={<QuotePage />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
