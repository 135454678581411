interface HeaderProps {
  title: string;
  sub: string;
}

const Header = ({ title, sub }: HeaderProps) => {
  return (
    <div className="flex flex-col items-center gap-[14px]">
      <p className="sm:text-xl text-grey font-[450] tracking-wider">{title}</p>
      <p className="px-4 sm:px-8 xl:px-0 text-sm sm:text-base w-full md:w-[620px] lg:w-[760px] text-center">{sub}</p>
    </div>
  );
};

export default Header;
