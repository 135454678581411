import ContactForm from "components/contact/contact-form";
import Faq from "components/faq";
import Footer from "components/footer";
import Navbar from "components/navbar";

const ContactPage = () => {
  return (
    <>
      <Navbar />
      <ContactForm />
      <Faq />
      <Footer />
    </>
  );
};

export default ContactPage;
