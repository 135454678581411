import Person from "assets/svg/person.svg";
import Quote from "assets/svg/quotes.svg";

const Testimonial = () => {
  const data = [
    {
      icon: Person,
      text: "Megaplux exceeded our expectations with their exceptional fabrication services. Their attention to detail and commitment to quality ensured that our project was completed to the highest standards. ",
      person: "Abudu Damilola",
      company: "Jupito Ltd",
    },
    {
      icon: Person,
      text: "I highly recommend Megaplux for their outstanding fabrication services. Their team is knowledgeable, responsive, and committed to excellence. The quality of their work has significantly contributed to the success of our projects",
      person: "Reyna Grobss",
      company: "Clients",
    },
    {
      icon: Person,
      text: "Megaplux provided us with custom metalwork that was both innovative and durable. Their customer service was excellent, and they were able to accommodate all our specific requirements.",
      person: "John Z.",
      company: "Sales Rep",
    },
    {
      icon: Person,
      text: "Megaplux exceeded our expectations with their exceptional fabrication services. Their attention to detail and commitment to quality ensured that our project was completed to the highest standards. ",
      person: "Aaden Lee",
      company: "Co-founder Lato",
    },
  ];

  return (
    <div className="w-full flex flex-col items-center py-20 gap-12">
      <p className="text-center text-2xl lg:text-[32px] font-medium text-[#000000]">
        Some words from our Clients
      </p>
      <div className="w-full px-4 xl:px-20 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-5">
        {data.map((items) => (
          <div
            className="w-full flex flex-col items-start gap-4 lg:gap-0 justify-between p-4 pb-8 border border-[#EAEAEA] rounded-2xl"
            key={items.person}
          >
            <img src={Quote} alt="" className="w-8 h-8" />

            <p className="py-2 text-sm text-[#5B5B5B]">{items.text}</p>

            <div className="w-full flex items-center gap-2">
              <img src={items.icon} alt="" />

              <div className="flex flex-col">
                <p className="text-lg font-medium text-dark">{items.person}</p>
                <p className="text-sm text-[#777777]">{items.company}</p>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Testimonial;
