import Faq from "components/faq";
import Footer from "components/footer";
import Navbar from "components/navbar";
import QuoteForm from "components/quote/quote-form";

const QuotePage = () => {
  return (
    <>
      <Navbar />
      <QuoteForm />
      <Faq />
      <Footer />
    </>
  );
};

export default QuotePage;
