import Footer from "components/footer";
import Navbar from "components/navbar";
import PageTitle from "utils/page-title";
import Section from "utils/section";
import Img from "assets/png/section.png";
import CoreValues from "ui/about-ui/core-values";
import Testimonial from "components/testimonial";

const AboutPage = () => {
  return (
    <>
      <Navbar active={3} />
      <PageTitle
        title="About Us"
        subtitle="Learn about who we are, our mission and Visions"
      />
      <Section
        image={Img}
        title="Who we are"
        subTextA="Megaplux Global Access Limited is an innovative indigenous fast growing brand fabrication company established and registered in Nigeria, 2007. We specialize in visual branding, large format print management, point of sale material fabrication and brand transformation with a holistic approach in our service delivery that adds strategic value to our client brands, products and reputation."
        subTextB="At Megaplux, we have a professional team providing creative concepts and designs, visual branding, large format print management and point of sales material (POSM) fabrication."
      />
      <Section
        image={Img}
        imgClass="md:order-2 h-[350px] object-cover"
        textClass="md:order-1"
        title="Our Vision"
        subTextA="To be the number one POSM company in Nigeria and West Africa, a company known for its intelligence, greatly exceeding customers’ expectations."
      />
      <Section
        image={Img}
        title="Our Mission"
        subTextA="To continually create Innovative quality products and services that provide strategic branding value to our clients’ brand, business and reputation."
        subTextB="Our aim is to work with our clients, embracing their marketing and communication strategies to produce visual expressions that convey their key messages and brand promises in diverse industries with great impact."
      />
      <CoreValues />
      <Testimonial />
      <Footer />
    </>
  );
};

export default AboutPage;
