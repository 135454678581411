import { useEffect, useRef } from "react";
import Client1 from "assets/svg/client1.svg";
import Client2 from "assets/svg/client2.svg";
import Client3 from "assets/svg/client3.svg";
import Client4 from "assets/svg/client4.svg";
import Client5 from "assets/svg/client5.svg";
import Client6 from "assets/svg/client6.svg";
import Client7 from "assets/svg/client7.svg";
import Client8 from "assets/svg/client8.svg";
import Client9 from "assets/svg/client9.svg";
import Client10 from "assets/svg/client10.svg";
import Client11 from "assets/svg/client11.svg";
import Client12 from "assets/svg/client12.svg";
import Client13 from "assets/svg/client13.svg";
import Client14 from "assets/svg/client14.svg";
import Client15 from "assets/svg/client15.svg";

const ImageSlider = () => {
  const images = [
    Client1,
    Client2,
    Client3,
    Client4,
    Client5,
    Client6,
    Client7,
    Client8,
    Client9,
    Client10,
    Client11,
    Client12,
    Client13,
    Client14,
    Client15,
  ];

  const sliderRef = useRef<any>(null);

  useEffect(() => {
    const slider = sliderRef.current;
    let scrollAmount = 0;
    let end = false;

    const autoScroll = () => {
      if (!slider) return;

      if (scrollAmount <= 647 && !end) {
        scrollAmount += 3;
      } else {
        end = true;
        scrollAmount -= 3;

        if (scrollAmount === 0) {
          end = false;
        }
      }

      slider.scrollTo({
        left: scrollAmount,
        behavior: "smooth",
      });
    };

    const sliderAction = () => {
      const interval = setInterval(autoScroll, 50);

      return interval;
    };

    const timeout = setTimeout(() => {
      sliderAction();
    }, 3000);

    return () => clearTimeout(timeout);
  }, []);

  return (
    <div className="w-full flex flex-col items-center gap-8 py-12">
      <p className="text-xl text-grey font-[450] tracking-wider">OUR CLIENTS</p>
      <div
        className="no-scrollbar overflow-x-auto scroll-smooth flex gap-8 whitespace-nowrap w-full"
        ref={sliderRef}
      >
        {images.map((image, index) => (
          <img
            key={index}
            src={image}
            alt={`Slide ${index + 1}`}
            className="object-contain h-[72px] flex-shrink-0"
          />
        ))}
      </div>
    </div>
  );
};

export default ImageSlider;
