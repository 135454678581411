const MessageIcon = () => {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.5 22.81C11.81 22.81 11.16 22.46 10.7 21.85L9.2 19.85C9.17 19.81 9.05 19.76 9 19.75H8.5C4.33 19.75 1.75 18.62 1.75 13V8C1.75 3.58 4.08 1.25 8.5 1.25H16.5C20.92 1.25 23.25 3.58 23.25 8V13C23.25 17.42 20.92 19.75 16.5 19.75H16C15.92 19.75 15.85 19.79 15.8 19.85L14.3 21.85C13.84 22.46 13.19 22.81 12.5 22.81ZM8.5 2.75C4.92 2.75 3.25 4.42 3.25 8V13C3.25 17.52 4.8 18.25 8.5 18.25H9C9.51 18.25 10.09 18.54 10.4 18.95L11.9 20.95C12.25 21.41 12.75 21.41 13.1 20.95L14.6 18.95C14.93 18.51 15.45 18.25 16 18.25H16.5C20.08 18.25 21.75 16.58 21.75 13V8C21.75 4.42 20.08 2.75 16.5 2.75H8.5Z"
        fill="currentColor"
      />
      <path
        d="M17.5 8.75H7.5C7.09 8.75 6.75 8.41 6.75 8C6.75 7.59 7.09 7.25 7.5 7.25H17.5C17.91 7.25 18.25 7.59 18.25 8C18.25 8.41 17.91 8.75 17.5 8.75Z"
        fill="currentColor"
      />
      <path
        d="M13.5 13.75H7.5C7.09 13.75 6.75 13.41 6.75 13C6.75 12.59 7.09 12.25 7.5 12.25H13.5C13.91 12.25 14.25 12.59 14.25 13C14.25 13.41 13.91 13.75 13.5 13.75Z"
        fill="currentColor"
      />
    </svg>
  );
};
export default MessageIcon;
