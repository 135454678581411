import Contact from "components/contact";
import Footer from "components/footer";
import Navbar from "components/navbar";
import HomeHero from "ui/home-ui/hero";
import HomeProjects from "ui/home-ui/projects";
import HomeServices from "ui/home-ui/services";
import ImageSlider from "utils/image-slider";

const HomePage = () => {
  return (
    <>
      <Navbar />
      <HomeHero />
      <HomeServices />
      <HomeProjects />
      <ImageSlider />
      <Contact />
      <Footer />
    </>
  );
};

export default HomePage;
