interface ButtonProps {
  label: any;
  className?: string;
  handleClick?: () => void;
}

const Button = ({ label, className, handleClick }: ButtonProps) => {
  return (
    <button
      type="button"
      className={`h-[52px] flex items-center justify-center gap-2 rounded-[52px] text-sm tracking-tight px-6 ${className}`}
      onClick={handleClick}
    >
      {label}
    </button>
  );
};

export default Button;
