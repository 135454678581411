interface Props {
  image: string;
  title: string;
  subTextA: string;
  subTextB?: string;
  imgClass?: string;
  textClass?: string;
}

const Section = ({
  image,
  title,
  subTextA,
  subTextB,
  imgClass,
  textClass,
}: Props) => {
  return (
    <div className="w-full grid grid-cols-1 md:grid-cols-2 py-8 md:py-16 px-4 sm:px-8 xl:px-20 items-center gap-6 lg:gap-14">
      <img src={image} alt="" className={`w-full rounded-2xl ${imgClass}`} />

      <div className={`w-full space-y-[14px] ${textClass}`}>
        <p className="text-2xl lg:text-[32px] text-grey">{title}</p>
        <p className="sm:text-lg md:text-sm lg:text-lg text-[#4f4f4f] font-light">{subTextA}</p>
        <p className="sm:text-lg md:text-sm lg:text-lg  text-[#4f4f4f] font-light">{subTextB}</p>
      </div>
    </div>
  );
};

export default Section;
