import MessageIcon from "assets/icon/MessageIcon";
import Logo from "assets/svg/logo.svg";
import { Link, useNavigate } from "react-router-dom";
import Button from "utils/button";
import { MdDehaze as MenuBtn } from "react-icons/md";
import { useState } from "react";

interface Props {
  active?: number;
}

const Navbar = ({ active }: Props) => {
  const navigate = useNavigate();
  const [open, setOpen] = useState<boolean>(false);

  const navLinks = [
    // { text: "Our Services", path: "/services", state: 1 },
    { text: "Projects", path: "/projects", state: 2 },
    { text: "About Us", path: "/about", state: 3 },
    { text: "Blogs", path: "/blogs", state: 4 },
  ];

  const handleNavigate = (path: string) => {
    navigate(path);
  };

  return (
    <>
      <div className="relative h-24">
        <div className="fixed top-0 left-0 w-full h-24 px-4 sm:px-8 xl:px-20 flex items-center justify-between bg-white z-50 border-b border-[#f2f2f2]">
          <div onClick={() => handleNavigate("/")} className="cursor-pointer">
            <img src={Logo} alt="" loading="eager" />
          </div>

          <div className="hidden lg:flex items-center gap-8">
            {navLinks.map((items) => (
              <div
                key={items.state}
                className={`h-[30px] ${
                  active === items?.state
                    ? "text-yellow border-b border-yellow"
                    : "text-black cursor-pointer"
                }`}
                onClick={() => handleNavigate(items.path)}
              >
                {items.text}
              </div>
            ))}
          </div>

          <Button
            className="hidden lg:flex bg-primary text-dark"
            label={
              <>
                <MessageIcon />
                <p>Send us a message</p>
              </>
            }
            handleClick={() => navigate("/contact")}
          />

          <MenuBtn
            className="flex lg:hidden cursor-pointer text-2xl"
            onClick={() => setOpen(!open)}
          />
        </div>
      </div>

      {open && (
        <div className="fixed w-full left-0 top-24 h-[calc(100dvh-96px)] bg-white z-50 pt-8 px-4 sm:px-8 flex flex-col gap-4">
          <Link to="/">Home</Link>
          <Link to="/about">About Us</Link>
          <Link to="/projects">Our Projects</Link>
          <a href="/">Blog</a>
          <Link to="/contact">Contact Us</Link>
        </div>
      )}
    </>
  );
};

export default Navbar;
