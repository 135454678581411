import Button from "utils/button";
import Arrow from "assets/svg/arrow-up-right.svg";
import MessageIcon from "assets/icon/MessageIcon";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const HomeHero = () => {
  const navigate = useNavigate();
  const [active, setActive] = useState<number>(1);

  useEffect(() => {
    setTimeout(() => {
      if (active === 3) {
        setActive(1);
      } else {
        setActive(active + 1);
      }
    }, 2000);
  }, [active]);

  return (
    <div className="home-bg py-10 sm:py-16 lg:h-[560px] flex flex-col items-center justify-center gap-6 xl:gap-[55px]">
      <p className="sm:w-[460px] lg:w-[780px] text-[24px] sm:text-[28px] lg:text-[48px] font-bold text-center text-white">
        We specialize in{" "}
        <span
          className={
            active === 1 ? "bg-yellow p-1 md:p-2 rounded-lg" : "p-1 md:p-2"
          }
        >
          Visual branding,
        </span>{" "}
        <span
          className={
            active === 2 ? "bg-[#9560CB] p-1 md:p-2 rounded-lg" : "p-1 md:p-2"
          }
        >
          large format Printing management,
        </span>{" "}
        <span
          className={
            active === 3 ? "bg-[#476765] p-1 md:p-2 rounded-lg" : "p-1 md:p-2"
          }
        >
          Point of Sale Management and
        </span>{" "}
        Brand transformation.
      </p>
      <div className="flex flex-col sm:flex-row gap-6 items-center">
        <Button
          className="bg-primary text-dark"
          label={
            <>
              <p>Explore Our Product</p>
              <img src={Arrow} alt="arrow" />
            </>
          }
          handleClick={() => navigate("/projects")}
        />
        <Button
          className="bg-transparent text-primary border border-primary"
          label={
            <>
              <MessageIcon />
              <p>Send us a message</p>
            </>
          }
          handleClick={() => navigate("/contact")}
        />
      </div>
    </div>
  );
};

export default HomeHero;
