interface Props {
  label: string;
  name: string;
  placeholder: string;
  type: string;
  value: string;
  setValue: any;
}

const TextInput = ({
  label,
  name,
  placeholder,
  type,
  value,
  setValue,
}: Props) => {
  return (
    <label className="w-full flex flex-col gap-2 text-sm text-[#000000] font-medium">
      {label}
      <input
        name={name}
        placeholder={placeholder}
        type={type}
        value={value}
        onChange={(e) => setValue(e.target.value)}
        className="h-[45px] rounded-lg border border-[#f2f2f2] outline-none py-2 px-4 text-sm placeholder:text-[#828282] text-[#000000]"
      />
    </label>
  );
};

export default TextInput;
