import Contact from "components/contact";
import Footer from "components/footer";
import Navbar from "components/navbar";
import FullProjects from "ui/product-ui/projects";
import PageTitle from "utils/page-title";

const ProjectsPage = () => {
  return (
    <>
      <Navbar active={2} />
      <PageTitle
        title="Our Projects"
        subtitle="Check out beautiful products we have for you"
      />
      <FullProjects />
      <Contact />
      <Footer />
    </>
  );
};

export default ProjectsPage;
