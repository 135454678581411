interface CardProps {
  image: any;
  title: string;
}

const ServicesCard = ({ image, title }: CardProps) => {
  return (
    <div className="w-full flex flex-col gap-[14px] relative">
      <div className="w-full relative group cursor-pointer">
        <img src={image} alt="" loading="lazy" />
        <div className="overlay-bg p-2 lg:p-6 absolute w-full h-full top-0 left-0 z-10 hidden group-hover:flex items-end text-sm md:text-lg lg:text-xl text-white rounded-2xl transition-all ease-in-out duration-500">
          We have a team of professionals responsible for project brief, visual
          ideas, concept and designs which forms the production of brand
          property elements like; Free standing units, Trade/experimental
          activation booths, signage, tents & gazebos, 3D billboards, display
          units etc
        </div>
      </div>
      <p className="lg:text-xl text-black">{title}</p>
    </div>
  );
};

export default ServicesCard;
