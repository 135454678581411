import Icon1 from "assets/svg/Quality.svg";
import Icon2 from "assets/svg/Handshake.svg";
import Icon3 from "assets/svg/Creativity.svg";
import Icon4 from "assets/svg/Innovation.svg";
import Icon5 from "assets/svg/Solution.svg";

const CoreValues = () => {
  const top = [
    {
      icon: Icon1,
      text: "Quality and Excellence",
    },
    {
      icon: Icon2,
      text: "Professionalism",
    },
    {
      icon: Icon3,
      text: "Creativity & Innovation",
    },
  ];

  const bottom = [
    {
      icon: Icon4,
      text: "Can do attitude",
    },
    {
      icon: Icon5,
      text: "Solution Oriented",
    },
  ];

  return (
    <div className="w-full flex flex-col items-center py-16 pb-24 gap-8 lg:gap-24 bg-[#333333]">
      <p className="text-2xl lg:text-[32px] text-center font-bold text-white">
        Our Core Values
      </p>

      <div className="w-full flex items-center justify-center gap-4 md:gap-8 lg:gap-16">
        {top.map((items) => (
          <div className="flex flex-col items-center gap-6" key={items.text}>
            <img src={items.icon} alt="" />
            <p className="text-center text-xs sm:text-base md:text-xl text-white">{items.text}</p>
          </div>
        ))}
      </div>

      <div className="w-full flex items-center justify-center gap-4 md:gap-8 lg:gap-16">
        {bottom.map((items) => (
          <div className="flex flex-col items-center gap-6" key={items.text}>
            <img src={items.icon} alt="" />
            <p className="text-center text-xs sm:text-base md:text-xl text-white">{items.text}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default CoreValues;
