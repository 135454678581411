import MessageIcon from "assets/icon/MessageIcon";
import { useNavigate } from "react-router-dom";
import Button from "utils/button";

const Contact = () => {
  const navigate = useNavigate();
  return (
    <div className="w-full px-4 py-10 sm:px-8 xl:p-20">
      <div className="py-10 xl:py-0 w-full flex flex-col gap-8 items-center justify-center lg:h-[380px] rounded-2xl bg-[#333333]">
        <p className="px-2 md:w-[445px] text-2xl sm:text-[32px] text-center font-bold text-white">
          Are you interested in giving us your project
        </p>
        <Button
          className="bg-primary text-dark"
          label={
            <>
              <MessageIcon />
              <p>Send us a message</p>
            </>
          }
          handleClick={() => navigate("/contact")}
        />
      </div>
    </div>
  );
};

export default Contact;
