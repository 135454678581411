import TextInput from "utils/form-input/text-input";
import ContactCard from "./contact-card";
import { useState } from "react";
import Button from "utils/button";
import TextareaInput from "utils/form-input/textarea-input";

const ContactForm = () => {
  const [FirstName, setFirstName] = useState("");
  const [LastName, setLastName] = useState("");
  const [Email, setEmail] = useState("");
  const [Phone, setPhone] = useState("");
  const [Service, setService] = useState("");
  const [Message, setMessage] = useState("");

  return (
    <div className="w-full px-4 sm:px-8 xl:px-20 py-10 grid grid-cols-1 lg:grid-cols-2 gap-8 items-center border-t border-[#f2f2f2]">
      <ContactCard />
      <div className="order-1 lg:order-2 w-full flex flex-col items-center gap-8">
        <div className="flex flex-col items-center">
          <p className="text-2xl lg:text-[32px] text-[#4f4f4f]">Send us a Message</p>
          <p className="text-[#4f4f4f] font-medium text-center">
            Kindly complete the form below to send us a message.
          </p>
        </div>

        <div className="flex w-full flex-col gap-4 lg:gap-6">
          <div className="w-full grid grid-cols-2 gap-4 lg:gap-6">
            <TextInput
              label="First name"
              name="firstName"
              placeholder="First Name"
              type="text"
              value={FirstName}
              setValue={setFirstName}
            />
            <TextInput
              label="Last name"
              name="lastName"
              placeholder="Last Name"
              type="text"
              value={LastName}
              setValue={setLastName}
            />
            <TextInput
              label="Email address"
              name="email"
              placeholder="Email Address"
              type="email"
              value={Email}
              setValue={setEmail}
            />
            <TextInput
              label="Phone number"
              name="phone"
              placeholder="Phone Number"
              type="text"
              value={Phone}
              setValue={setPhone}
            />
          </div>

          <TextInput
            label="Service Type"
            name="service"
            placeholder="Service Type"
            type="text"
            value={Service}
            setValue={setService}
          />

          <TextareaInput
            label="Message"
            name="message"
            placeholder="Message"
            value={Message}
            setValue={setMessage}
          />

          <Button
            label="Submit"
            className="bg-[#FFB539] text-black w-full h-[55px]"
          />
        </div>
      </div>
    </div>
  );
};

export default ContactForm;
