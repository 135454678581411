import { useState } from "react";
import { useNavigate } from "react-router-dom";
import Button from "utils/button";

const Faq = () => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [clickId, setClickId] = useState(0);

  const list = [
    { id: 1, title: "How long does the prototype takes", sub: "Content" },
    {
      id: 2,
      title: "Can one POSM work for both indoor and outdoor?",
      sub: "Content",
    },
    {
      id: 3,
      title: "Can one POSM work for both indoor and outdoor?",
      sub: "Content",
    },
    {
      id: 4,
      title: "Can one POSM work for both indoor and outdoor?",
      sub: "Content",
    },
    {
      id: 5,
      title: "Can one POSM work for both indoor and outdoor?",
      sub: "Content",
    },
  ];

  const handleClick = (id: number) => {
    setOpen(!open);
    setClickId(id);
  };

  return (
    <div className="w-full lg:px-8 xl:px-20 py-10">
      <div className="w-full p-6 grid grid-cols-1 lg:grid-cols-[40%,auto] gap-6 lg:gap-20 bg-[#333333] lg:rounded-2xl items-center">
        <div className="flex flex-col gap-4 items-start text-white">
          <p className="text-[32px] font-bold">Check Our FAQs</p>
          <p>
            Have a question about our services? Our FAQ section has got you
            covered with helpful information on all of our offerings.
          </p>
          <p>
            Do you have any questions or could not find what you are looking
            for?
          </p>
          <Button
            label="Contact Us"
            className="w-[200px] bg-transparent border border-white text-white"
            handleClick={() => navigate("/contact")}
          />
        </div>

        <div className="w-full flex flex-col">
          {list.map((item) => (
            <div
              className="w-full h-auto py-3 px-2 lg:py-4 lg:pr-2 border-t border-b border-[rgba(255,255,255,0.1)] flex flex-col gap-3"
              key={item.id}
            >
              <div className="w-full flex flex-row items-center justify-between gap-4">
                <p className="text-white text-sm lg:text-xl font-medium">{item.title}</p>
                <p
                  className="text-primary text-2xl font-black cursor-pointer"
                  onClick={() => handleClick(item.id)}
                >
                  {open && clickId === item.id ? "-" : "+"}
                </p>
              </div>
              {open && clickId === item.id ? (
                <p className="text-white">{item.sub}</p>
              ) : null}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Faq;
