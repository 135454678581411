import Img1 from "assets/png/proj1.png";
import Img2 from "assets/png/proj2.png";
import Img3 from "assets/png/proj3.png";
import Img4 from "assets/png/proj4.png";
import Img5 from "assets/png/proj5.png";
import Img6 from "assets/png/proj6.png";
import Arrow from "assets/svg/arrow-up-right.svg";

// utils
import Header from "utils/header";
import Button from "utils/button";
import ProjectCard from "utils/card/project-card";
import { useNavigate } from "react-router-dom";

const HomeProjects = () => {
  const navigate = useNavigate();

  const data = [
    {
      image: Img1,
      title: "₦25,000.00",
      product: "Dark Steel Roller Metal Cupboard",
    },
    {
      image: Img2,
      title: "₦25,000.00",
      product: "Dark Steel Roller Metal Cupboard",
    },
    {
      image: Img3,
      title: "₦25,000.00",
      product: "Dark Steel Roller Metal Cupboard",
    },
    {
      image: Img4,
      title: "₦25,000.00",
      product: "Dark Steel Roller Metal Cupboard",
    },
    {
      image: Img5,
      title: "₦25,000.00",
      product: "Dark Steel Roller Metal Cupboard",
    },
    {
      image: Img6,
      title: "₦25,000.00",
      product: "Dark Steel Roller Metal Cupboard",
    },
  ];

  return (
    <div className="w-full flex flex-col items-center gap-14 py-12">
      <Header
        title="OUR PROJECTS"
        sub="We strive to exceed the expectations of every client by offering outstanding customer service, increased operation efficiency, and greater value for money, thus optimizing brand functionality in the marketplace"
      />

      <div className="px-4 sm:px-8 xl:px-20 w-full grid grid-cols-2 lg:grid-cols-3 gap-6">
        {data.map((items, index) => (
          <ProjectCard
            image={items.image}
            title={items.title}
            product={items.product}
            key={index}
          />
        ))}
      </div>

      <Button
        className="bg-primary text-dark"
        label={
          <>
            <p>View all projects</p>
            <img src={Arrow} alt="arrow" />
          </>
        }
        handleClick={() => navigate("/projects")}
      />
    </div>
  );
};

export default HomeProjects;
