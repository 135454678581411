import Button from "utils/button";
import Arrow from "assets/svg/arrow-up-right.svg";
import { useNavigate } from "react-router-dom";

interface CardProps {
  image: any;
  title: string;
  product: string;
}

const ProjectCard = ({ image, title, product }: CardProps) => {
  const navigate = useNavigate();

  return (
    <div className="w-full flex flex-col gap-[14px] group">
      <img src={image} alt="" loading="lazy" />

      <p className="flex group-hover:hidden lg:text-xl text-black">{title}</p>

      <div className="hidden group-hover:flex flex-col gap-2 items-start">
        <p className="text-sm lg:text-base text-black">{product}</p>
        <Button
          className="bg-primary text-dark px-10"
          label={
            <>
              <p>Get a Quote</p>
              <img src={Arrow} alt="arrow" />
            </>
          }
          handleClick={() => navigate("/quote")}
        />
      </div>
    </div>
  );
};

export default ProjectCard;
