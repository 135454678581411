import Social1 from "assets/svg/Facebook.svg";
import Social2 from "assets/svg/Instagram.svg";
import Social3 from "assets/svg/Linkedin.svg";
import Social4 from "assets/svg/Twitter.svg";
import Icon1 from "assets/svg/call-calling.svg";
import Icon2 from "assets/svg/messages.svg";
import Icon3 from "assets/svg/map.svg";
import Icon4 from "assets/svg/clock.svg";

const ContactCard = () => {
  const data = [
    {
      icon: Icon1,
      title: "Speak with our Experts",
      content: ["08099966077", "08163044777"],
    },
    {
      icon: Icon2,
      title: "Send us a mail",
      content: [
        "Our friendly team is here to help you. Send a mail",
        "to info@megapluxglobal.com",
      ],
    },
    {
      icon: Icon3,
      title: "Visit us",
      content: ["Plot 398B Herbert Macaulay Way, Jibowu/Yaba. Lagos Nigeria."],
    },
    {
      icon: Icon4,
      title: "Operating Hours",
      content: ["Monday – Friday", "9am – 6pm"],
    },
  ];

  return (
    <div className="order-2 lg:order-1 w-full rounded-2xl p-4 pt-8 lg:p-10 pb-16 bg-[#333333] flex flex-col items-start gap-8 lg:gap-20">
      <div className="flex flex-col gap-8">
        <p className="text-2xl font-medium text-white">Say a Hello to us</p>

        {data.map((item) => (
          <div className="w-full flex items-start gap-4" key={item.title}>
            <img src={item.icon} alt="" className="w-6 h-6" />
            <div className="flex flex-col gap-2">
              <p className="text-[rgba(255,255,255,0.5)] font-medium">
                {item.title}
              </p>
              <div>
                {item.content.map((text) => (
                  <p
                    className="text-white text-sm font-medium leading-6"
                    key={text}
                  >
                    {text}
                  </p>
                ))}
              </div>
            </div>
          </div>
        ))}
      </div>

      <div className="flex flex-col gap-6">
        <p className="text-white font-[450]">Connect with us on social media</p>
        <div className="flex gap-5">
          <img
            src={Social1}
            alt="facebook"
            className="w-8 h-8 cursor-pointer"
          />
          <img
            src={Social2}
            alt="instagram"
            className="w-8 h-8 cursor-pointer"
          />
          <img
            src={Social3}
            alt="linkedin"
            className="w-8 h-8 cursor-pointer"
          />
          <img src={Social4} alt="twitter" className="w-8 h-8 cursor-pointer" />
        </div>
      </div>
    </div>
  );
};

export default ContactCard;
