import Img1 from "assets/png/mega-fab.png";
import Img2 from "assets/png/mega-print.png";
import Img3 from "assets/png/mega-props.png";
import Img4 from "assets/png/mega-e.png";

// utils
import Header from "utils/header";
import ServicesCard from "utils/card/service-card";

const HomeServices = () => {
  const data = [
    { image: Img1, title: "Mega Fabrication" },
    { image: Img2, title: "Mega Print" },
    { image: Img3, title: "Mega Props and Merchandising" },
    { image: Img4, title: "Mega E&E" },
  ];

  return (
    <div className="w-full flex flex-col items-center gap-14 py-12">
      <Header
        title="OUR SERVICE"
        sub="We strive to exceed the expectations of every client by offering
          outstanding customer service, increased operation efficiency, and
          greater value for money, thus optimizing brand functionality in the
          marketplace"
      />

      <div className="px-4 sm:px-8 xl:px-20 w-full grid grid-cols-1 sm:grid-cols-2 gap-4 xl:gap-6">
        {data.map((items, index) => (
          <ServicesCard image={items.image} title={items.title} key={index} />
        ))}
      </div>
    </div>
  );
};

export default HomeServices;
