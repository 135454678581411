import { Link } from "react-router-dom";
import Logo from "assets/svg/logo.svg";
import Social1 from "assets/svg/Facebook.svg";
import Social2 from "assets/svg/Instagram.svg";
import Social3 from "assets/svg/Linkedin.svg";
import Social4 from "assets/svg/Twitter.svg";

const Footer = () => {
  return (
    <div className="w-full border-t border-[#F2F2FF] pt-10 pb-16 px-4 sm:px-8 xl:px-20 flex flex-col lg:flex-row items-start gap-6 justify-between">
      <Link to="/">
        <img src={Logo} alt="" loading="eager" />
      </Link>

      <div className="flex flex-col sm:flex-row items-start gap-6 xl:gap-10">
        <div className="flex flex-col gap-1">
          <p className="text-sm text-grey">COMPANY</p>
          <Link to="/about" className="text-sm text-[#333333]">
            About Us
          </Link>
          <Link to="/projects" className="text-sm text-[#333333]">
            Projects
          </Link>
          <Link to="/" className="text-sm text-[#333333]">
            Blogs
          </Link>
        </div>

        <div className="flex flex-col gap-4">
          <div className="flex flex-col gap-1">
            <p className="text-sm text-grey">VISIT US</p>
            <p className="text-sm text-[#333333]">
              Plot 398B Herbert Macaulay Way, Jibowu/Yaba.
              <br /> Lagos Nigeria.
            </p>
          </div>

          <div className="flex flex-col gap-1">
            <p className="text-sm text-grey">OPERATING HOURS</p>
            <p className="text-sm text-[#333333]">
              Monday - Friday (9am - 6pm)
            </p>
          </div>
        </div>

        <div className="flex flex-col gap-1">
          <p className="text-sm text-grey">CONTACT US</p>
          <p className="text-sm text-[#333333]">08099966077, 08163044777</p>
          <p className="text-sm text-[#333333]">info@megapluxglobal.com</p>
        </div>
      </div>

      <div className="flex gap-5">
        <img src={Social1} alt="facebook" className="w-8 h-8 cursor-pointer" />
        <img src={Social2} alt="instagram" className="w-8 h-8 cursor-pointer" />
        <img src={Social3} alt="linkedin" className="w-8 h-8 cursor-pointer" />
        <img src={Social4} alt="twitter" className="w-8 h-8 cursor-pointer" />
      </div>
    </div>
  );
};

export default Footer;
