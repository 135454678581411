interface Props {
  title: string;
  subtitle: string;
}

const PageTitle = ({ title, subtitle }: Props) => {
  return (
    <div className="w-full py-10 lg:h-[290px] flex flex-col items-center justify-center gap-6 page-bg text-white">
      <p className="text-2xl sm:text-[32px] lg:text-[40px] font-bold">{title}</p>
      <p className="text-base sm:text-lg lg:text-[20px] text-center">{subtitle}</p>
    </div>
  );
};

export default PageTitle;
